import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link } from 'react-router-dom'
import ContainerWrapper from './ContainerWrapper'

export default function ForgotPassword() {
	const emailRef = useRef()
	const { resetPassword } = useAuth()
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')

	async function handleSubmit(e) {
		e.preventDefault()

		try {
			setMessage('')
			setError('')
			setLoading(true)
			await resetPassword(emailRef.current.value)
			setMessage('Sjekk epostinnboksen din!')
		} catch {
			setError('Noko gjekk galt! Kunne ikkje gjenoppretta passord')
		}
		setLoading(false)
	}

	return (
		<ContainerWrapper minHeight="100vh">
			<Card>
				<Card.Body>
					<h2 className="text-center mb-4">Gjenopprett passord</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					{message && <Alert variant="success">{message}</Alert>}
					<Form onSubmit={handleSubmit}>
						<Form.Group id="email">
							<Form.Label>Epost</Form.Label>
							<Form.Control type="email" ref={emailRef} required />
						</Form.Group>
						<Button disabled={loading} className="w-100 mt-2 dark-blue-button" type="submit">
							Gjenopprett passord
						</Button>
					</Form>
					<div className="w-100 text-center mt-3">
						<Link to="/login">Logg inn</Link>
					</div>
				</Card.Body>
			</Card>
			<div className="w-100 text-center mt-2">
				Har du ikkje ein brukar? <Link to="/signup">Lag ein her</Link>
			</div>
		</ContainerWrapper>
	)
}
