import React from 'react'
import { Container } from 'react-bootstrap'

export default function ContainerWrapper({ minHeight = '75vh', children, ...rest }) {
	return (
		<Container className="d-flex align-items-center justify-content-center" style={{ minHeight }}>
			<div className="w-100" style={{ maxWidth: '400px' }}>
				{React.Children.map(children, (child) =>
					React.cloneElement(child, {
						style: { maxWidth: '400px', ...child.props.style },
					})
				)}
			</div>
		</Container>
	)
}
