import React, { useRef, useState, useEffect } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { addNewUserToMyBurgersCollection, getUserNames } from '../database'
import { auth } from '../firebase'
import ContainerWrapper from './ContainerWrapper'

export default function Signup() {
	const emailRef = useRef()
	const usernameRef = useRef()
	const passwordRef = useRef()
	const { signup } = useAuth()
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [usernames, setUsernames] = useState(null)
	const navigate = useNavigate()

	async function asyncSetUsernames() {
		setUsernames(await getUserNames())
	}

	useEffect(() => {
		asyncSetUsernames()
	}, [])

	async function handleSubmit(e) {
		e.preventDefault()

		try {
			setError('')
			setLoading(true)
			if (usernames.includes(usernameRef.current.value.toLowerCase())) {
				setError('Brukarnamnet er allereie i bruk!')
			} else {
				await signup(emailRef.current.value, passwordRef.current.value)

				const authStateChangeListener = new Promise((resolve) => {
					const unsubscribe = auth.onAuthStateChanged((user) => {
						unsubscribe()
						resolve(user)
					})
				})
				const userObj = await authStateChangeListener

				await addNewUserToMyBurgersCollection(userObj.uid, usernameRef.current.value)
				navigate('/menu')
			}
		} catch {
			setError('Noko gjekk galt! Brukaren er ikkje laga')
		}
		setLoading(false)
	}

	return (
		<ContainerWrapper minHeight="100vh">
			<Card>
				<Card.Body>
					<h2 className="text-center mb-4">Lag ny brukar</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<Form.Group id="email">
							<Form.Label>Epost</Form.Label>
							<Form.Control type="email" ref={emailRef} required />
						</Form.Group>
						<Form.Group id="username">
							<Form.Label>Brukarnamn</Form.Label>
							<Form.Control type="username" ref={usernameRef} required />
						</Form.Group>
						<Form.Group id="password">
							<Form.Label>Passord</Form.Label>
							<Form.Control type="password" ref={passwordRef} required />
						</Form.Group>
						<Button disabled={loading} className="w-100 mt-2 dark-blue-button" type="submit">
							Lag ny brukar
						</Button>
					</Form>
				</Card.Body>
			</Card>
			<div className="w-100 text-center mt-2">
				Har du allereie ein brukar? <Link to="/login">Logg inn her</Link>
			</div>
		</ContainerWrapper>
	)
}
