import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import ContainerWrapper from './ContainerWrapper'

export default function Login() {
	const emailRef = useRef()
	const usernameRef = useRef()
	const passwordRef = useRef()
	const { login } = useAuth()
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	async function handleSubmit(e) {
		e.preventDefault()

		try {
			setError('')
			setLoading(true)
			await login(emailRef.current.value, passwordRef.current.value)
			navigate('/menu')
		} catch {
			setError('Noko gjekk galt! Kunne ikkje logga inn')
		}
		setLoading(false)
	}

	return (
		<ContainerWrapper minHeight="100vh">
			<Card>
				<Card.Body>
					<h2 className="text-center mb-4">Logg inn</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<Form.Group id="email">
							<Form.Label>Epost</Form.Label>
							<Form.Control type="email" ref={emailRef} required />
						</Form.Group>
						<Form.Group id="username">
							<Form.Label>Brukarnamn</Form.Label>
							<Form.Control type="username" ref={usernameRef} />
						</Form.Group>
						<Form.Group id="password">
							<Form.Label>Passord</Form.Label>
							<Form.Control type="password" ref={passwordRef} required />
						</Form.Group>
						<Button disabled={loading} className="w-100 mt-2 dark-blue-button" type="submit">
							Logg inn
						</Button>
					</Form>
					<div className="w-100 text-center mt-3">
						<Link to="/forgot-password">Har du gløymt passordet ditt?</Link>
					</div>
				</Card.Body>
			</Card>
			<div className="w-100 text-center mt-2">
				Har du ikkje ein brukar? <Link to="/signup">Lag ein her</Link>
			</div>
		</ContainerWrapper>
	)
}
