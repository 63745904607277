import React from 'react'
import { Alert, Button, Modal, Form, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'

export default function AddLocation({
	showLocationEdit,
	handleCloseLocationEdit,
	newLocation,
	setNewLocation,
	loadingLocationEdit,
	handleSubmitLocation,
	errorLocationEdit,
}) {
	return (
		<Modal show={showLocationEdit} onHide={handleCloseLocationEdit}>
			<Modal.Header closeButton>
				<Modal.Title>Legg til ein ny lokasjon</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="locationForm.ControlInput1">
						<Form.Label>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: '0.5rem' }}>Ny lokasjon</div>
								<OverlayTrigger
									placement="right"
									overlay={
										<Tooltip>
											Før du legg til ein ny lokasjon må du vera sikker på at lokasjonen ikkje er
											lagt til frå før
										</Tooltip>
									}
								>
									<Badge bg="secondary">!</Badge>
								</OverlayTrigger>
							</div>
						</Form.Label>
						<Form.Control
							type="text"
							placeholder="Lokasjon"
							value={newLocation}
							onChange={(e) => setNewLocation(e.target.value)}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{errorLocationEdit && <Alert variant="danger">{errorLocationEdit}</Alert>}
				<Button variant="secondary" onClick={handleCloseLocationEdit}>
					Avbryt
				</Button>
				<Button disabled={loadingLocationEdit} className="dark-blue-button" onClick={handleSubmitLocation}>
					Lagra lokasjon
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
