import React from 'react'
import { Alert, Button, Modal, Form } from 'react-bootstrap'

export default function UpdateWishlist({
	title,
	showUpdateWishlist,
	handleCloseUpdateWishlist,
	burgerplaceRef,
	availableBurgerplaces,
	loadingUpdateWishlist,
	handleSubmitWishlist,
	errorUpdateWishlist,
}) {
	return (
		<Modal show={showUpdateWishlist} onHide={handleCloseUpdateWishlist}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="wishlistForm.ControlInput1">
						<Form.Label>Burgerplass</Form.Label>
						<Form.Select custom="true" ref={burgerplaceRef}>
							{availableBurgerplaces?.map((burgerplace, index) => (
								<option key={index} value={burgerplace}>
									{burgerplace}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{errorUpdateWishlist && <Alert variant="danger">{errorUpdateWishlist}</Alert>}
				<Button variant="secondary" onClick={handleCloseUpdateWishlist}>
					Avbryt
				</Button>
				<Button disabled={loadingUpdateWishlist} className="dark-blue-button" onClick={handleSubmitWishlist}>
					Oppdater ønskeliste
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
