import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { DARK_BLUE_RGB, YELLOW_RGB } from '../helpers'
import ContainerWrapper from './ContainerWrapper'

export default function Frontpage() {
	const navigate = useNavigate()

	return (
		<div style={{ backgroundColor: DARK_BLUE_RGB, height: '100vh' }}>
			<ContainerWrapper>
				<div className="d-flex flex-column align-items-center justify-content-center">
					<img src={`${process.env.PUBLIC_URL}/images/logo.png`} width="250" alt="Logo for Burgervurdering" />
					<h1 style={{ color: YELLOW_RGB, marginBottom: '2rem' }}>Burgervurdering</h1>
					<div className="d-grid gap-3">
						<Button
							variant="outline-secondary"
							style={{ borderColor: YELLOW_RGB, color: YELLOW_RGB }}
							onClick={() => navigate('/login')}
						>
							Logg inn
						</Button>
						<Button
							variant="outline-secondary"
							style={{ borderColor: YELLOW_RGB, color: YELLOW_RGB }}
							onClick={() => navigate('/signup')}
						>
							Lag ny brukar
						</Button>
					</div>
				</div>
			</ContainerWrapper>
		</div>
	)
}
