import React from 'react'
import { PiHamburgerThin, PiHamburgerFill } from 'react-icons/pi'
import { IconContext } from 'react-icons'
import { YELLOW_RGB } from '../helpers'

export default function Burgermeter({ points, totalBurgers }) {
	const maxPoints = totalBurgers === 3 ? 30 : 50
	const percentValue = Math.round((points / maxPoints) * 100)
	const filledBurgers = Math.floor((percentValue / 100) * totalBurgers)

	const PercentageFilledHamburgerIcon = ({ fillWidth }) => {
		const partialBurgerWidth = `${fillWidth}%`
		const fillPosition = `${fillWidth / 2}%`
		return (
			<div style={{ display: 'flex', position: 'relative' }}>
				<PiHamburgerThin color={YELLOW_RGB} size={16} />
				<div
					style={{
						position: 'absolute',
						top: '40%',
						left: fillPosition,
						transform: 'translate(-50%, -50%)',
						overflow: 'hidden',
						width: partialBurgerWidth,
					}}
				>
					<PiHamburgerFill color={YELLOW_RGB} size={16} />
				</div>
			</div>
		)
	}

	const emptyBurgers = totalBurgers - filledBurgers - (percentValue > 0 ? 1 : 0)

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			<IconContext.Provider value={{ color: YELLOW_RGB }}>
				{[...Array(filledBurgers)].map((_, i) => (
					<PiHamburgerFill key={`burger-${i}`} />
				))}
				<PercentageFilledHamburgerIcon fillWidth={Math.floor((points / 10 - filledBurgers) * 100)} />
				{[...Array(emptyBurgers)].map((_, i) => (
					<PiHamburgerThin key={`burger-${filledBurgers + 1 + i}`} />
				))}
			</IconContext.Provider>
		</div>
	)
}
