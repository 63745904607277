import React, { useState, useEffect } from 'react'
import { Accordion, Alert, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Header from './Header'
import Assessment from './Assessment'
import { getAssessmentOrWishlistExists, getAssessmentsForBurgerPlace, updateMyWishlist } from '../database'
import Burgermeter from './Burgermeter'
import { useAuth } from '../contexts/AuthContext'
import { YELLOW_RGB } from '../helpers'
import ContainerWrapper from './ContainerWrapper'

export default function AllAssessmentsForBurgerPlace() {
	const [assessments, setAssessments] = useState(null)
	const [displayName, setDisplayName] = useState(null)
	const [isLoadingAssessments, setIsLoadingAssessments] = useState(true)
	const [assessmentsNotFound, setAssessmentsNotFound] = useState(false)
	const [userHasAssessmentOrWishlist, setUserHasAssessmentOrWishlist] = useState(true)
	const [errorUpdateWishlist, setErrorUpdateWishlist] = useState('')
	const [loadingUpdateWishlist, setLoadingUpdateWishlist] = useState(false)
	const { burgerplace } = useParams()
	const { currentUser } = useAuth()

	async function asyncSetAssessments(burgerPlaceId) {
		setIsLoadingAssessments(true)
		const assessmentsData = await getAssessmentsForBurgerPlace(burgerPlaceId)
		setAssessments(assessmentsData)
		setIsLoadingAssessments(false)
	}

	async function asyncSetUserHasAssessmentOrWishlist(userId, burgerPlaceId, burgerPlaceName) {
		setUserHasAssessmentOrWishlist(await getAssessmentOrWishlistExists(userId, burgerPlaceId, burgerPlaceName))
	}

	async function handleUpdateWishlist(e) {
		e.preventDefault()

		try {
			setErrorUpdateWishlist('')
			setLoadingUpdateWishlist(true)
			await updateMyWishlist(currentUser.uid, displayName)
			setUserHasAssessmentOrWishlist(true)
		} catch {
			setErrorUpdateWishlist('Noko gjekk galt! Kunne ikkje legga til ny burgerplass')
		}
		setLoadingUpdateWishlist(false)
	}

	useEffect(() => {
		asyncSetAssessments(burgerplace)
	}, [burgerplace])

	useEffect(() => {
		if (!isLoadingAssessments) {
			if (assessments !== null && Object.keys(assessments).length > 0) {
				setAssessmentsNotFound(false)
				setDisplayName(assessments?.[0]?.displayName)
				asyncSetUserHasAssessmentOrWishlist(currentUser.uid, burgerplace, assessments?.[0]?.displayName)
			} else {
				setAssessmentsNotFound(true)
			}
		}
		// eslint-disable-next-line
	}, [assessments, isLoadingAssessments, setUserHasAssessmentOrWishlist])

	return (
		<>
			<Header />
			<ContainerWrapper>
				<>
					{assessmentsNotFound && !isLoadingAssessments && (
						<div className="text-center">
							<h5 className="mb-3">Det er ingen registrerte burgerplassar som har denne id-en.</h5>
							<a style={{ color: YELLOW_RGB }} href="/menu">
								Gå tilbake til menyen
							</a>
						</div>
					)}
					{displayName && (
						<div className="text-center my-3">
							<h2>
								Alle vurderingar av <br /> {displayName}
							</h2>
							{!userHasAssessmentOrWishlist && (
								<Button
									className="wide-dark-blue-button"
									disabled={loadingUpdateWishlist}
									onClick={handleUpdateWishlist}
								>
									Legg til burgerplass i ønskeliste
								</Button>
							)}
							{errorUpdateWishlist && (
								<Alert variant="danger" className="mt-2">
									{errorUpdateWishlist}
								</Alert>
							)}
						</div>
					)}
					<Accordion>
						{assessments?.map((assessment, innerIndex) => (
							<div key={innerIndex}>
								<Accordion.Item eventKey={innerIndex}>
									<Accordion.Header style={{ position: 'relative' }}>
										<div>{assessment.username}</div>
										<div style={{ position: 'absolute', right: 50 }}>
											<Burgermeter points={assessment.score} totalBurgers={5} />
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<Assessment burgerPlace={assessment} />
									</Accordion.Body>
								</Accordion.Item>
								<div className="p-1" />
							</div>
						))}
					</Accordion>
					<div className="p-3" />
				</>
			</ContainerWrapper>
		</>
	)
}
