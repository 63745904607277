import React, { useEffect, useState } from 'react'
import { Card, Button, Alert } from 'react-bootstrap'
import Header from './Header'
import { useNavigate } from 'react-router-dom'
import ContainerWrapper from './ContainerWrapper'
import { config } from '../firebase'
import { CiWarning } from 'react-icons/ci'

export default function Menu() {
	const [ongoingWork, setOngoingWork] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		config.fetchAndActivate().then(() => {
			const value = config.getValue('ongoingWork').asBoolean()
			setOngoingWork(value)
		})
	}, [])

	return (
		<>
			<Header />
			<ContainerWrapper>
				<Card>
					<Card.Body>
						{ongoingWork && (
							<Alert variant="warning">
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<CiWarning size={90} style={{ marginRight: '10px' }} />
									<div>Akkurat no blir det jobba på Burgervurdering og feil kan derfor oppstå!</div>
								</div>
							</Alert>
						)}
						<h2 className="text-center mb-3">Burgervurdering</h2>
						<h5 className="text-center mb-5">Kor finn ein den beste baconcheeseburgeren?</h5>
						<div className="d-grid gap-3">
							<Button className="dark-blue-button" onClick={() => navigate('/my-assessments')}>
								Mine vurderingar
							</Button>
							<Button className="dark-blue-button" onClick={() => navigate('/my-burgerplaces')}>
								Mine burgerplassar
							</Button>
							<Button className="dark-blue-button" onClick={() => navigate('/all-assessments')}>
								Alle vurderingar
							</Button>
							<Button className="dark-blue-button" onClick={() => navigate('/rating')}>
								Toppliste
							</Button>
						</div>
					</Card.Body>
				</Card>
			</ContainerWrapper>
		</>
	)
}
