import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Header from './Header'
import { getBurgerPlacesWithId } from '../database'
import { useNavigate } from 'react-router-dom'
import ContainerWrapper from './ContainerWrapper'

export default function AllAssessments() {
	const [allBurgerPlaces, setAllBurgerPlaces] = useState([])
	const [loadingAllBurgerPlaces, setLoadingAllBurgerPlaces] = useState(true)
	const navigate = useNavigate()

	async function asyncSetAllBurgerPlaces() {
		setAllBurgerPlaces(await getBurgerPlacesWithId())
		setLoadingAllBurgerPlaces(false)
	}

	useEffect(() => {
		asyncSetAllBurgerPlaces()
	}, [])

	if (loadingAllBurgerPlaces) {
		return null
	}

	return (
		<>
			<Header />
			<ContainerWrapper>
				<h2 className="text-center my-5">Alle vurderingar</h2>
				<Form.Select className="mb-3" onChange={(e) => navigate(`/all-assessments/${e.target.value}`)}>
					<option>Velg burgerplass</option>
					{allBurgerPlaces
						?.sort((a, b) => a.displayName.localeCompare(b.displayName))
						.filter((b) => b.nAssessments > 0)
						.map((burgerPlace, index) => (
							<option key={index} value={burgerPlace.id}>
								{burgerPlace.displayName}
							</option>
						))}
				</Form.Select>
			</ContainerWrapper>
		</>
	)
}
