import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/remote-config'

const app = firebase.initializeApp({
	apiKey: 'AIzaSyBeIZyuYIPykp1t8w5W11FHc9ilhzOG_lA',
	authDomain: 'burgervurdering.firebaseapp.com',
	projectId: 'burgervurdering',
	storageBucket: 'burgervurdering.appspot.com',
	messagingSenderId: '976125811654',
	appId: '1:976125811654:web:38f3d29e011ac393bbb0c7',
})

export const auth = app.auth()
export const db = app.firestore()
export const storage = app.storage()
export const config = app.remoteConfig()
config.settings.minimumFetchIntervalMillis = 0
export default app
