import React, { useState, useEffect, useRef } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import Header from './Header'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { getMyBurgers, getMyWishlist, getBurgerPlacesWithId, updateMyWishlist, deleteFromMyWishlist } from '../database'
import { asyncGetDatabaseName } from '../helpers.js'
import UpdateWishlist from './Modals/Burgerplaces/UpdateWishlist.js'
import ContainerWrapper from './ContainerWrapper.js'

export default function MyBurgerPlaces() {
	const burgerplaceRef = useRef()
	const burgerplaceToDeleteRef = useRef()
	const [myBurgers, setMyBurgers] = useState()
	const [myWishlist, setMyWishlist] = useState()
	const [availableBurgerPlaces, setAvailableBurgerPlaces] = useState()
	const [showUpdateWishlist, setShowUpdateWishlist] = useState(false)
	const [errorUpdateWishlist, setErrorUpdateWishlist] = useState('')
	const [showDeleteWishlist, setShowDeleteWishlist] = useState(false)
	const [errorDeleteWishlist, setErrorDeleteWishlist] = useState('')
	const [loadingUpdateWishlist, setLoadingUpdateWishlist] = useState(false)
	const [loadingDeleteWishlist, setLoadingDeleteWishlist] = useState(false)
	const { currentUser } = useAuth()
	const navigate = useNavigate()

	async function asyncSetMyBurgers() {
		const myBurgersData = await getMyBurgers(currentUser.uid)
		myBurgersData.sort((a, b) => a.displayName.localeCompare(b.displayName))
		setMyBurgers(myBurgersData)
	}

	async function asyncSetMyWishlist() {
		const myWishlistData = await getMyWishlist(currentUser.uid)
		myWishlistData.sort((a, b) => a.localeCompare(b))
		setMyWishlist(myWishlistData)
	}

	async function asyncSetAvailableBurgerPlaces() {
		const allBurgerPlaces = await getBurgerPlacesWithId()
		const myBurgersData = await getMyBurgers(currentUser.uid)
		const myBurgerPlaces = myBurgersData?.map((burgerData) => burgerData.displayName)
		const myWishlistData = await getMyWishlist(currentUser.uid)
		const burgerplacesWithoutAssessment = allBurgerPlaces?.filter(
			(burgerPlace) =>
				!myBurgerPlaces.includes(burgerPlace.displayName) && !myWishlistData.includes(burgerPlace.displayName)
		)
		const availableDisplayNames = burgerplacesWithoutAssessment.map((b) => b.displayName).sort()
		setAvailableBurgerPlaces(availableDisplayNames)
	}

	async function handleCreateAllAssessmentsLink(displayName) {
		const databaseName = await asyncGetDatabaseName(displayName)
		navigate(`/all-assessments/${databaseName}`)
	}

	useEffect(() => {
		asyncSetMyBurgers()
		asyncSetMyWishlist()
		asyncSetAvailableBurgerPlaces()
		// eslint-disable-next-line
	}, [showUpdateWishlist, showDeleteWishlist])

	async function handleSubmitUpdateWishlist(e) {
		e.preventDefault()

		try {
			setErrorUpdateWishlist('')
			setLoadingUpdateWishlist(true)
			await updateMyWishlist(currentUser.uid, burgerplaceRef.current.value)
			setShowUpdateWishlist(false)
		} catch {
			setErrorUpdateWishlist('Noko gjekk galt! Kunne ikkje legga til ny burgerplass')
		}
		setLoadingUpdateWishlist(false)
	}

	async function handleSubmitDeleteWishlist(e) {
		e.preventDefault()

		try {
			setErrorDeleteWishlist('')
			setLoadingDeleteWishlist(true)
			await deleteFromMyWishlist(currentUser.uid, burgerplaceToDeleteRef.current.value)
			setShowDeleteWishlist(false)
		} catch {
			setErrorDeleteWishlist('Noko gjekk galt! Kunne ikkje legga til ny burgerplass')
		}
		setLoadingDeleteWishlist(false)
	}

	const handleShowUpdateWishlist = () => setShowUpdateWishlist(true)
	const handleCloseUpdateWishlist = () => {
		setShowUpdateWishlist(false)
		setErrorUpdateWishlist('')
	}

	const handleShowDeleteWishlist = () => setShowDeleteWishlist(true)
	const handleCloseDeleteWishlist = () => {
		setShowDeleteWishlist(false)
		setErrorDeleteWishlist('')
	}

	return (
		<>
			<Header />
			<ContainerWrapper>
				<h2 className="text-center my-5">Mine burgerplassar</h2>
				<Accordion>
					<Accordion.Item eventKey={0}>
						<Accordion.Header>Burgerplassar eg vil besøka</Accordion.Header>
						<Accordion.Body>
							{myWishlist?.map((b) => (
								<li
									key={b}
									style={{ cursor: 'pointer' }}
									onClick={() => handleCreateAllAssessmentsLink(b)}
								>
									{b}
								</li>
							))}
							<div style={{ textAlign: 'center', marginTop: '15px' }}>
								{availableBurgerPlaces?.length > 0 && (
									<Button
										className="wide-dark-blue-button"
										style={{ marginBottom: '5px' }}
										onClick={handleShowUpdateWishlist}
									>
										Legg til burgerplass
									</Button>
								)}
								{myWishlist?.length > 0 && (
									<Button className="wide-dark-blue-button" onClick={handleShowDeleteWishlist}>
										Slett burgerplass
									</Button>
								)}
							</div>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey={1}>
						<Accordion.Header>Burgerplassar eg har vurdert</Accordion.Header>
						<Accordion.Body>
							{myBurgers?.map((assessment) => (
								<li
									key={assessment.displayName}
									style={{ cursor: 'pointer' }}
									onClick={() => navigate('/my-assessments')}
								>
									{assessment.displayName}
								</li>
							))}
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</ContainerWrapper>

			<UpdateWishlist
				title={'Legg til ein burgerplass du vil besøka'}
				showUpdateWishlist={showUpdateWishlist}
				handleCloseUpdateWishlist={handleCloseUpdateWishlist}
				burgerplaceRef={burgerplaceRef}
				availableBurgerplaces={availableBurgerPlaces}
				loadingUpdateWishlist={loadingUpdateWishlist}
				handleSubmitWishlist={handleSubmitUpdateWishlist}
				errorUpdateWishlist={errorUpdateWishlist}
			/>

			<UpdateWishlist
				title={'Slett ein burgerplass frå ønskelista di'}
				showUpdateWishlist={showDeleteWishlist}
				handleCloseUpdateWishlist={handleCloseDeleteWishlist}
				burgerplaceRef={burgerplaceToDeleteRef}
				availableBurgerplaces={myWishlist}
				loadingUpdateWishlist={loadingDeleteWishlist}
				handleSubmitWishlist={handleSubmitDeleteWishlist}
				errorUpdateWishlist={errorDeleteWishlist}
			/>
		</>
	)
}
