import React from 'react'
import { Alert, Button, Modal, Form, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'

export default function AddBurgerPlace({
	showBurgerPlace,
	handleCloseBurgerPlace,
	displayNameRef,
	nameRef,
	urlRef,
	loadingBurgerPlace,
	handleSubmitBurgerPlace,
	locations,
	handleCheckboxChange,
	errorBurgerPlace,
}) {
	return (
		<Modal show={showBurgerPlace} onHide={handleCloseBurgerPlace}>
			<Modal.Header closeButton>
				<Modal.Title>Legg til ein ny burgerplass</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="burgerPlaceForm.ControlInput1">
						<Form.Label>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: '0.5rem' }}>Namn</div>
								<OverlayTrigger
									placement="right"
									overlay={
										<Tooltip>
											Før du legg til ein ny burgerplass må du vera sikker på at plassen ikkje er
											lagt til frå før
										</Tooltip>
									}
								>
									<Badge bg="secondary">!</Badge>
								</OverlayTrigger>
							</div>
						</Form.Label>
						<Form.Control type="text" placeholder="Namn på burgerplass" ref={displayNameRef} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="burgerPlaceForm.ControlInput2">
						<Form.Label>Namn i databasen</Form.Label>
						<Form.Control type="text" placeholder="namn-på-burgerplass" ref={nameRef} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="burgerPlaceForm.ControlInput3">
						<Form.Label>Link til heimeside</Form.Label>
						<Form.Control type="url" placeholder="www.burgerplass.no" ref={urlRef} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="burgerPlaceForm.ControlInput4">
						<Form.Label>Lokasjonar</Form.Label>
						{locations?.map((location, index) => {
							return (
								<Form.Check
									key={index}
									label={location}
									type="checkbox"
									value={location}
									onChange={handleCheckboxChange}
								/>
							)
						})}
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{errorBurgerPlace && <Alert variant="danger">{errorBurgerPlace}</Alert>}
				<Button variant="secondary" onClick={handleCloseBurgerPlace}>
					Avbryt
				</Button>
				<Button disabled={loadingBurgerPlace} className="dark-blue-button" onClick={handleSubmitBurgerPlace}>
					Lagra burgerplass
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
