import React, { useState, useRef, useCallback } from 'react'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import { setNewFeedback } from '../../../database'

export default function AddFeedback({ showNewFeedback, setShowNewFeedback, email }) {
	const titleRef = useRef()
	const messageRef = useRef()
	const [validated, setValidated] = useState(false)
	const [loadingSetNewFeedback, setLoadingNewFeedback] = useState(false)
	const [errorNewFeedback, setErrorNewFeedback] = useState('')

	async function handleSubmitNewFeedback(e) {
		e.preventDefault()
		try {
			setErrorNewFeedback('')
			setLoadingNewFeedback(true)
			await setNewFeedback(titleRef.current.value, messageRef.current.value, email)
			setShowNewFeedback(false)
		} catch {
			setErrorNewFeedback('Noko gjekk galt! Kunne ikkje senda inn tilbakemelding')
		}
		setLoadingNewFeedback(false)
	}

	const handleMessageChange = useCallback(
		(e) => {
			setValidated(
				!!e.target.value &&
					!!messageRef.current?.value &&
					e.target.checkValidity() &&
					messageRef.current?.checkValidity()
			)
		},
		[messageRef]
	)

	const handleTitleChange = useCallback(
		(e) => {
			setValidated(
				!!titleRef.current?.value &&
					!!e.target.value &&
					titleRef.current?.checkValidity() &&
					e.target.checkValidity()
			)
		},
		[titleRef]
	)

	function handleClose() {
		setShowNewFeedback(false)
		setErrorNewFeedback('')
		setValidated(false)
	}

	return (
		<Modal show={showNewFeedback} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Send inn ny tilbakemelding</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<Form validated={validated}>
						<Form.Group className="mb-3" controlId="feedbackForm.ControlInput1">
							<Form.Label style={{ marginTop: '10px' }}>Tittel</Form.Label>
							<Form.Control
								required
								maxLength={25}
								type="text"
								ref={titleRef}
								onChange={handleTitleChange}
							/>
							<Form.Control.Feedback type="invalid">Du må ha ein tittel</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="mb-3" controlId="feedbackForm.ControlInput2">
							<Form.Label style={{ marginTop: '10px' }}>Tilbakemelding</Form.Label>
							<Form.Control
								required
								as="textarea"
								rows={3}
								ref={messageRef}
								onChange={handleMessageChange}
							/>
							<Form.Control.Feedback type="invalid">Du må ha ei melding</Form.Control.Feedback>
						</Form.Group>
					</Form>
					<p style={{ fontSize: '0.8em' }}>
						<em>Tilbakemeldingar er ikkje anonyme</em>
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				{errorNewFeedback && <Alert variant="danger">{errorNewFeedback}</Alert>}
				<Button variant="secondary" onClick={handleClose}>
					Avbryt
				</Button>
				<Button
					disabled={loadingSetNewFeedback || !validated}
					className="dark-blue-button"
					onClick={handleSubmitNewFeedback}
				>
					Lagra tilbakemelding
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
