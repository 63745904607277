import React from 'react'
import { YELLOW_RGB } from '../helpers'
import Header from './Header'
import ContainerWrapper from './ContainerWrapper'

export default function NotFound() {
	return (
		<>
			<Header />
			<ContainerWrapper>
				<div className="text-center">
					<h5 className="mb-3">Denne sida finst ikkje.</h5>
					<a style={{ color: YELLOW_RGB }} href="/menu">
						Gå tilbake til menyen
					</a>
				</div>
			</ContainerWrapper>
		</>
	)
}
