import React from 'react'
import { Alert, Button, Modal, Form, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import RangeAnnotation from '../../RangeAnnotation'

export default function EditAssessment({
	showEdit,
	handleCloseEdit,
	assessmentToEdit,
	handleRangeChange,
	handleCommentChange,
	handleEditAssessmentImageChange,
	loadingEdit,
	handleSubmitEdit,
	errorEdit,
}) {
	return (
		<Modal show={showEdit} onHide={handleCloseEdit}>
			<Modal.Header closeButton>
				<Modal.Title>Rediger vurdering</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="editForm.ControlInput1">
						<Form.Label>
							Burgerplass: <strong>{assessmentToEdit?.displayName}</strong>
						</Form.Label>
					</Form.Group>
					<Form.Group className="mb-3" controlId="editForm.ControlInput2">
						<Form.Label style={{ marginTop: '10px' }}>Kjøtt/vegetaralternativ</Form.Label>
						<Form.Range
							value={assessmentToEdit?.meat}
							onChange={(e) => handleRangeChange('meat', parseInt(e.target.value))}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="editForm.ControlInput3">
						<Form.Label style={{ marginTop: '10px' }}>Brød</Form.Label>
						<Form.Range
							value={assessmentToEdit?.bun}
							onChange={(e) => handleRangeChange('bun', parseInt(e.target.value))}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="editForm.ControlInput4">
						<Form.Label style={{ marginTop: '10px' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: '0.5rem' }}>Tilbehør</div>
								<OverlayTrigger
									placement="right"
									overlay={
										<Tooltip>
											Alt som er på burgeren som ikkje er kjøttet/vegetaralternativet
										</Tooltip>
									}
								>
									<Badge bg="secondary">i</Badge>
								</OverlayTrigger>
							</div>
						</Form.Label>
						<Form.Range
							value={assessmentToEdit?.extra}
							onChange={(e) => handleRangeChange('extra', parseInt(e.target.value))}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="editForm.ControlInput5">
						<Form.Label style={{ marginTop: '10px' }}>Pommes</Form.Label>
						<Form.Range
							value={assessmentToEdit?.fries}
							onChange={(e) => handleRangeChange('fries', parseInt(e.target.value))}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="editForm.ControlInput6">
						<Form.Label style={{ marginTop: '10px' }}>Dip</Form.Label>
						<Form.Range
							value={assessmentToEdit?.dip}
							onChange={(e) => handleRangeChange('dip', parseInt(e.target.value))}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="editForm.ControlInput7">
						<Form.Label style={{ marginTop: '10px' }}>Kommentar</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							placeholder=""
							value={assessmentToEdit?.comment}
							onChange={handleCommentChange}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="editForm.ControlInput8">
						<Form.Label>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: '0.5rem' }}>Last opp eit bilete av burgeren</div>
								{assessmentToEdit?.url && assessmentToEdit?.url !== '' && (
									<OverlayTrigger
										placement="left"
										overlay={
											<Tooltip>
												Det finst allereie eit bilete for denne vurderinga, men det er ingenting
												som stoppar deg frå å lasta opp eit nytt for å erstatta det gamle!
											</Tooltip>
										}
									>
										<Badge bg="secondary">!</Badge>
									</OverlayTrigger>
								)}
							</div>
						</Form.Label>
						<Form.Control type="file" onChange={handleEditAssessmentImageChange} />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{errorEdit && <Alert variant="danger">{errorEdit}</Alert>}
				<Button variant="secondary" onClick={handleCloseEdit}>
					Avbryt
				</Button>
				<Button disabled={loadingEdit} className="dark-blue-button" onClick={handleSubmitEdit}>
					Lagra vurdering
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
