import React from 'react'
import { Modal } from 'react-bootstrap'
import { BarChart, XAxis, Bar, YAxis, Tooltip } from 'recharts'
import { DARK_BLUE_RGB, YELLOW_RGB } from '../../../helpers'

const CustomTooltip = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<div className="custom-tooltip">
				<p style={{ color: YELLOW_RGB }}>{payload[0].value} vurderingar</p>
			</div>
		)
	}
	return null
}

const CustomBarChart = ({ data, xDataKey, yDataKey }) => {
	return (
		<BarChart width={320} height={250} data={data} margin={{ top: 10, right: 20, bottom: 10, left: 0 }}>
			<XAxis dataKey={xDataKey} />
			<YAxis width={30} />
			<Bar dataKey={yDataKey} fill={DARK_BLUE_RGB} />
			<Tooltip content={<CustomTooltip />} />
		</BarChart>
	)
}

export default function ReadStatistics({ showStatistics, handleCloseStatistics, statistics }) {
	return (
		<Modal show={showStatistics} onHide={handleCloseStatistics}>
			<Modal.Header closeButton>
				<Modal.Title>Statistikk</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3>Nøkkeltal</h3>
				Det er lagt til <strong>{statistics.nBurgerPlaces}</strong> burgerplassar,{' '}
				<strong>{statistics.nBurgerPlacesWithAssessments}</strong> av desse har blitt vurdert.
				<br />
				Burgerplassane er fordelt på <strong>{statistics.nLocations}</strong> ulike lokasjonar.
				<br />
				Det finst <strong>{statistics.nUsers}</strong> brukarar og totalt{' '}
				<strong>{statistics.nAssessments}</strong> vurderingar.
				<br />
				<h3 style={{ marginTop: '30px' }}>Brukarar med flest vurderingar</h3>
				<CustomBarChart
					data={statistics.usersAndAssessments?.slice(0, 5)}
					xDataKey={'username'}
					yDataKey={'nAssessments'}
				/>
				<h3 style={{ marginTop: '30px' }}>Burgerplassar med flest vurderingar</h3>
				<CustomBarChart
					data={statistics.burgerPlacesAndAssessments?.slice(0, 3)}
					xDataKey={'burgerPlaceName'}
					yDataKey={'nAssessments'}
				/>
			</Modal.Body>
		</Modal>
	)
}
