import React from 'react'
import { Alert, Button, Modal, Form, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import RangeAnnotation from '../../RangeAnnotation'

export default function AddAssessment({
	showNewAssessment,
	handleCloseNewAssessment,
	displayNameRef,
	burgerPlaces,
	meatRef,
	bunRef,
	extraRef,
	friesRef,
	dipRef,
	commentRef,
	handleNewAssessmentImageChange,
	loadingNewAssessment,
	handleSubmitNewAssessment,
	errorNewAssessment,
}) {
	return (
		<Modal show={showNewAssessment} onHide={handleCloseNewAssessment}>
			<Modal.Header closeButton>
				<Modal.Title>Legg til ny vurdering</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="assessmentForm.ControlInput1">
						<Form.Label>Burgerplass</Form.Label>
						<Form.Select custom="true" ref={displayNameRef}>
							{burgerPlaces
								?.sort((a, b) => a.displayName.localeCompare(b.displayName))
								.map((burgerPlace, index) => (
									<option key={index} value={burgerPlace.displayName}>
										{burgerPlace.displayName}
									</option>
								))}
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="assessmentForm.ControlInput2">
						<Form.Label style={{ marginTop: '10px' }}>Kjøtt/vegetaralternativ</Form.Label>
						<Form.Range
							ref={meatRef}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="assessmentForm.ControlInput3">
						<Form.Label style={{ marginTop: '10px' }}>Brød</Form.Label>
						<Form.Range
							ref={bunRef}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="assessmentForm.ControlInput4">
						<Form.Label style={{ marginTop: '10px' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: '0.5rem' }}>Tilbehør</div>
								<OverlayTrigger
									placement="right"
									overlay={
										<Tooltip>
											Alt som er på burgeren som ikkje er kjøttet/vegetaralternativet
										</Tooltip>
									}
								>
									<Badge bg="secondary">i</Badge>
								</OverlayTrigger>
							</div>
						</Form.Label>
						<Form.Range
							ref={extraRef}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="assessmentForm.ControlInput5">
						<Form.Label style={{ marginTop: '10px' }}>Pommes</Form.Label>
						<Form.Range
							ref={friesRef}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="assessmentForm.ControlInput6">
						<Form.Label style={{ marginTop: '10px' }}>Dip</Form.Label>
						<Form.Range
							ref={dipRef}
							custom="true"
							min="1"
							max="10"
							step="1"
							style={{ background: 'transparent' }}
						/>
						<RangeAnnotation />
					</Form.Group>
					<Form.Group className="mb-3" controlId="assessmentForm.ControlInput7">
						<Form.Label style={{ marginTop: '10px' }}>Kommentar</Form.Label>
						<Form.Control as="textarea" rows={3} placeholder="" ref={commentRef} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="assessmentForm.ControlInput8">
						<Form.Label style={{ marginTop: '10px' }}>Last opp eit bilete av burgeren</Form.Label>
						<Form.Control type="file" onChange={handleNewAssessmentImageChange} />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{errorNewAssessment && <Alert variant="danger">{errorNewAssessment}</Alert>}
				<Button variant="secondary" onClick={handleCloseNewAssessment}>
					Avbryt
				</Button>
				<Button
					disabled={loadingNewAssessment}
					className="dark-blue-button"
					onClick={handleSubmitNewAssessment}
				>
					Lagra vurdering
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
